import styled from 'styled-components';

export const ErrorWrapper = styled.div`
  padding: 80px 15px;
  height: 100vh;
  display: flex;
  align-items: center;
`;

export const ErrorContent = styled.div`
  width: 500px;
  max-width: 100%;
  margin: 0 auto;

  .image-wrapper {
    @media (max-width: 1480px) {
      max-width: 80%;
      margin-left: auto;
      margin-right: auto;
    }
  }
`;

export const ButtonWrapper = styled.div`
  text-align: center;
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
`;

export const styles = {
  navbarStyle: {
    minHeight: '70px',
    display: 'block',
  },
  logoStyle: {
    maxWidth: ['120px', '130px'],
  },
  imageWrapper: {
    mb: ['40px', '55px'],
  },
  title: {
    fontSize: ['38px', '48px'],
    color: 'white',
    letterSpacing: '-0.025em',
    mb: ['25px', '35px'],
    lineHeight: '1.31',
    textAlign: 'center',
    fontWeight: '600',
  },
  text: {
    fontSize: ['18px'],
    lineHeight: '2',
    mb: ['40px', '50px', '60px'],
    textAlign: 'center',
    color: 'white',
  },
  actionButton: {
    type: 'button',
    iconPosition: 'left',
    p: ['1.1rem 2rem', '1.3rem 3rem'],
  },
};
