import styled from 'styled-components';
import Button from '..';
import { generalButtonStyles } from '../variants';

export const OutlinedButtonStyle = styled(Button)`
  color: white;
  background: transparent;
  outline: 1px solid white;
  &:hover {
    color: white;
  }
`;

export const styles = {
  button: {
    ...generalButtonStyles,
    colors: 'secondaryWithBg',
    color: 'white',
  },
};
