import React from 'react';
import Seo from 'components/seo';
import ErrorSec from 'containers/Error';
import { ResetCSS } from 'common/assets/css/style';
import { ThemeProvider } from 'styled-components';
import { syncStageTheme } from 'common/theme/syncStage';
import { GlobalStyle, ContentWrapper } from 'containers/SyncStage/syncStage.style';

const NotFoundPage = () => (
  <ThemeProvider theme={syncStageTheme}>
    <Seo title="SyncStage | Ultra Fast Audio Pipeline" />
    <ResetCSS />
    <GlobalStyle />
    <ContentWrapper>
      <ErrorSec />
    </ContentWrapper>
  </ThemeProvider>
);

export default NotFoundPage;
