import React from 'react';
import { Link } from 'gatsby';
import Icon from 'react-icons-kit';
import Box from 'common/components/Box';
import Text from 'common/components/Text';
import Heading from 'common/components/Heading';
import Image from 'common/components/Image';
import ErrorImage from 'common/assets/image/404.svg';
import { home } from 'react-icons-kit/entypo/home';
import { ccw } from 'react-icons-kit/entypo/ccw';
import { ErrorWrapper, ErrorContent, ButtonWrapper, styles } from './error.style';
import PrimaryButton from 'common/components/Button/PrimaryButton/PrimaryButton';
import OutlinedButton from 'common/components/Button/OutlinedButton/OutlinedButton';
import NavbarWrapper from 'common/components/Navbar';
import Logo from 'common/components/UIElements/Logo';
import LogoImage from 'common/assets/image/syncStage/logo.png';
import Container from 'common/components/UI/Container';

const ErrorSec = () => {
  const { imageWrapper, title, text, actionButton, navbarStyle, logoStyle } = styles;
  const pageReload = () => {
    window.location.reload();
  };
  return (
    <>
      <NavbarWrapper {...navbarStyle} className="saas_navbar">
        <Container>
          <Logo href="/" logoSrc={LogoImage} title="SyncStage" logoStyle={logoStyle} className="main-logo" />
        </Container>
      </NavbarWrapper>
      <ErrorWrapper>
        <ErrorContent>
          <Box {...imageWrapper} className="image-wrapper">
            <Image src={ErrorImage} alt="404" />
          </Box>
          <Heading {...title} content="Page not found!" />
          <Text
            {...text}
            content="Looks like the page you're trying to visit doesn't exist. Please check the URL and try your luck again."
          />
          <ButtonWrapper>
            <PrimaryButton
              {...actionButton}
              title="Reload Page"
              icon={<Icon icon={ccw} size={24} />}
              onClick={pageReload}
            />
            <Link href="/" style={{ flex: 1 }}>
              <OutlinedButton {...actionButton} title="Go Home" icon={<Icon icon={home} size={24} />} />
            </Link>
          </ButtonWrapper>
        </ErrorContent>
      </ErrorWrapper>
    </>
  );
};

export default ErrorSec;
